import * as React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core"
import MainPageSlider from "../components/mainPageSlider"
import Seo from "../components/seo"
import Card from "../components/mainPage/dayProduct"
import Categories from "../components/mainPage/categories"
import News from "../components/mainPage/news"
import Advantages from "../components/mainPage/advantages"
import Sales from "../components/mainPage/sales"
import Hot from "../components/mainPage/hot"
import NewProducts from "../components/mainPage/newProducts"
import Popular from "../components/mainPage/popular"
import SocialNetworks from "../components/mainPage/social"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

const useStyles = makeStyles(theme => ({
  day_news_categories: {
    display: "grid",
    gridTemplateColumns: "1fr 854px",
    gap: 40,
    rowGap: 0,
    "& .index--day--product": {
      gridRow: "span 2",
    },
    "@media (max-width: 1279px)": {
      gap: 22,
      rowGap: 40,
      gridTemplateColumns: "1fr auto",
      "& .index--day--product": {
        gridRow: 2,
      },
    },
    "@media (max-width: 767px)": {
      "& .index--day--product": {
        gridColumn: "span 2",
      },
    },
  },
}))

const IndexPage = ({ data }) => {
  const classes = useStyles()

  const allSales = data.allPrismicSales.edges.map(edge => edge.node)

  const allPromotionBanners = data.allPrismicPromotionBanner.edges.map(
    edge => edge.node
  )

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">
          {`{
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": "Krypton.ru",
    "url": "https://www.krypton.ru",
    "description": "Krypton – интернет-маркетплейс, который вы так долго искали | Krypton.ru",
    "publisher": {
      "@type": "Organization",
      "name": "Krypton.ru",
      "logo": {
        "@type": "ImageObject",
        "url": "${data?.allPrismicHeader?.edges[0]?.node.data.logo_svg.localFile.publicURL}",
        "width": 189,
        "height": 56
      }
    }
  }`}
        </script>
      </Helmet>
      <Seo title="Krypton – интернет-маркетплейс, который вы так долго искали" />
      <div style={{ marginBottom: 28 }} />
      <MainPageSlider array={allSales} variant={"sales"} />
      <div style={{ marginBottom: 40 }} />
      <div className={classes.day_news_categories}>
        <Card data={data} />
        <News data={data.allPrismicStories} />
        <Categories data={data.allPrismicCategory} />
      </div>
      <Advantages data={data} />
      <Sales data={data} />
      <div style={{ marginBottom: 80 }} />
      <Hot data={data} />
      <div style={{ marginBottom: 80 }} />
      <MainPageSlider array={allPromotionBanners} variant={"promotionBanner"} />
      <div style={{ marginBottom: 80 }} />
      <NewProducts data={data} />
      <div style={{ marginBottom: 80 }} />
      <Popular data={data} />
      <div style={{ marginBottom: 100 }} />
      <SocialNetworks data={data} />
    </Layout>
  )
}

/**
 * Главная страница
 * @module src/page/index
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученый из prismic
 */
export default IndexPage

export const query = graphql`
  query MainPage {
    allPrismicMainPage {
      edges {
        node {
          data {
            hot {
              hot_product {
                document {
                  ... on PrismicProduct {
                    id
                    uid
                    data {
                      code_model
                      brand {
                        uid
                        document {
                          ... on PrismicBrand {
                            data {
                              name
                            }
                          }
                        }
                      }
                      category {
                        uid
                        document {
                          ... on PrismicSubcategory {
                            data {
                              name
                            }
                          }
                        }
                      }
                      all_product_accessories {
                        product_accessories {
                          document {
                            ... on PrismicProduct {
                              id
                              uid
                              data {
                                code_model
                                brand {
                                  uid
                                  document {
                                    ... on PrismicBrand {
                                      data {
                                        name
                                      }
                                    }
                                  }
                                }
                                category {
                                  uid
                                  document {
                                    ... on PrismicSubcategory {
                                      data {
                                        name
                                      }
                                    }
                                  }
                                }
                                name
                                price
                                old_price
                                images {
                                  image {
                                    localFile {
                                      childImageSharp {
                                        gatsbyImageData
                                      }
                                    }
                                    alt
                                  }
                                }
                                delivery {
                                  document {
                                    ... on PrismicDelivery {
                                      data {
                                        body {
                                          ... on PrismicDeliveryBodyDeliveryToCities {
                                            id
                                            items {
                                              city_name
                                              cost
                                              delivery_description
                                              timing
                                            }
                                          }
                                        }
                                        variants {
                                          description
                                          name
                                        }
                                      }
                                    }
                                  }
                                }
                                credit {
                                  document {
                                    ... on PrismicCredit {
                                      data {
                                        months_1
                                        months_2
                                        percent
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      name
                      price
                      old_price
                      images {
                        image {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(height: 200)
                            }
                          }
                        }
                      }
                      delivery {
                        document {
                          ... on PrismicDelivery {
                            data {
                              body {
                                ... on PrismicDeliveryBodyDeliveryToCities {
                                  id
                                  items {
                                    city_name
                                    cost
                                    delivery_description
                                    timing
                                  }
                                }
                              }
                              variants {
                                description
                                name
                              }
                            }
                          }
                        }
                      }
                      credit {
                        document {
                          ... on PrismicCredit {
                            data {
                              months_1
                              months_2
                              percent
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            new {
              new_product {
                document {
                  ... on PrismicProduct {
                    id
                    uid
                    data {
                      code_model
                      brand {
                        uid
                        document {
                          ... on PrismicBrand {
                            data {
                              name
                            }
                          }
                        }
                      }
                      category {
                        uid
                        document {
                          ... on PrismicSubcategory {
                            data {
                              name
                            }
                          }
                        }
                      }
                      all_product_accessories {
                        product_accessories {
                          document {
                            ... on PrismicProduct {
                              id
                              uid
                              data {
                                code_model
                                brand {
                                  uid
                                  document {
                                    ... on PrismicBrand {
                                      data {
                                        name
                                      }
                                    }
                                  }
                                }
                                category {
                                  uid
                                  document {
                                    ... on PrismicSubcategory {
                                      data {
                                        name
                                      }
                                    }
                                  }
                                }
                                name
                                price
                                old_price
                                images {
                                  image {
                                    localFile {
                                      childImageSharp {
                                        gatsbyImageData
                                      }
                                    }
                                    alt
                                  }
                                }
                                delivery {
                                  document {
                                    ... on PrismicDelivery {
                                      data {
                                        body {
                                          ... on PrismicDeliveryBodyDeliveryToCities {
                                            id
                                            items {
                                              city_name
                                              cost
                                              delivery_description
                                              timing
                                            }
                                          }
                                        }
                                        variants {
                                          description
                                          name
                                        }
                                      }
                                    }
                                  }
                                }
                                credit {
                                  document {
                                    ... on PrismicCredit {
                                      data {
                                        months_1
                                        months_2
                                        percent
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      name
                      price
                      old_price
                      images {
                        image {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(height: 200)
                            }
                          }
                        }
                      }
                      delivery {
                        document {
                          ... on PrismicDelivery {
                            data {
                              body {
                                ... on PrismicDeliveryBodyDeliveryToCities {
                                  id
                                  items {
                                    city_name
                                    cost
                                    delivery_description
                                    timing
                                  }
                                }
                              }
                              variants {
                                description
                                name
                              }
                            }
                          }
                        }
                      }
                      credit {
                        document {
                          ... on PrismicCredit {
                            data {
                              months_1
                              months_2
                              percent
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicBrand {
      edges {
        node {
          data {
            name
            body {
              ... on PrismicBrandBodyLogo {
                id
                primary {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          height: 100
                          width: 100
                          transformOptions: { fit: CONTAIN }
                        )
                      }
                    }
                  }
                }
              }
            }
            popular
          }
        }
      }
    }
    allPrismicHeader {
      edges {
        node {
          data {
            logo_svg {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    allPrismicSale {
      edges {
        node {
          data {
            sale_img {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 297
                    transformOptions: { fit: CONTAIN }
                  )
                }
              }
            }
            sale_name {
              raw
            }
            sale_value
            link
            tumbler_link
          }
        }
      }
    }
    allPrismicAdvantage(sort: { fields: data___priority }) {
      edges {
        node {
          data {
            adv_img {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 194
                    transformOptions: { fit: CONTAIN }
                  )
                }
              }
            }
            adv_title {
              raw
            }
          }
        }
      }
    }
    allPrismicSales {
      edges {
        node {
          uid
          data {
            creationdate
            enddate
            startdate
            previewimage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1144
                    transformOptions: { fit: CONTAIN }
                  )
                }
              }
              alt
            }
            image_mobile {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1144
                    transformOptions: { fit: CONTAIN }
                  )
                }
              }
              alt
            }
            previewtext {
              text
            }
            title {
              text
            }
            salestext {
              html
            }
            link {
              text
            }
          }
        }
      }
    }
    allPrismicStories {
      edges {
        node {
          data {
            link
            tumbler_link
            text {
              text
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 144
                    transformOptions: { fit: CONTAIN }
                  )
                }
              }
            }
          }
        }
      }
    }
    allPrismicPromotionBanner {
      edges {
        node {
          data {
            link
            tumbler_link
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1144
                    transformOptions: { fit: CONTAIN }
                  )
                }
              }
              alt
            }
            image_mobile {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1144
                    transformOptions: { fit: CONTAIN }
                  )
                }
              }
              alt
            }
          }
        }
      }
    }
    allPrismicDayProduct {
      edges {
        node {
          uid
          data {
            product {
              uid
              document {
                ... on PrismicProduct {
                  id
                  uid
                  data {
                    code_model
                    category {
                      uid
                      document {
                        ... on PrismicSubcategory {
                          data {
                            name
                          }
                        }
                      }
                    }
                    brand {
                      document {
                        ... on PrismicBrand {
                          data {
                            name
                          }
                        }
                      }
                    }
                    all_product_accessories {
                      product_accessories {
                        document {
                          ... on PrismicProduct {
                            id
                            uid
                            data {
                              code_model
                              brand {
                                uid
                                document {
                                  ... on PrismicBrand {
                                    data {
                                      name
                                    }
                                  }
                                }
                              }
                              category {
                                uid
                                document {
                                  ... on PrismicSubcategory {
                                    data {
                                      name
                                    }
                                  }
                                }
                              }
                              name
                              price
                              old_price
                              images {
                                image {
                                  localFile {
                                    childImageSharp {
                                      gatsbyImageData
                                    }
                                  }
                                  alt
                                }
                              }
                              delivery {
                                document {
                                  ... on PrismicDelivery {
                                    data {
                                      body {
                                        ... on PrismicDeliveryBodyDeliveryToCities {
                                          id
                                          items {
                                            city_name
                                            cost
                                            delivery_description
                                            timing
                                          }
                                        }
                                      }
                                      variants {
                                        description
                                        name
                                      }
                                    }
                                  }
                                }
                              }
                              credit {
                                document {
                                  ... on PrismicCredit {
                                    data {
                                      months_1
                                      months_2
                                      percent
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    name
                    price
                    old_price
                    images {
                      image {
                        alt
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              height: 200
                              transformOptions: { fit: CONTAIN }
                            )
                          }
                        }
                      }
                    }
                    delivery {
                      document {
                        ... on PrismicDelivery {
                          data {
                            body {
                              ... on PrismicDeliveryBodyDeliveryToCities {
                                id
                                items {
                                  city_name
                                  cost
                                  delivery_description
                                  timing
                                }
                              }
                            }
                            variants {
                              description
                              name
                            }
                          }
                        }
                      }
                    }
                    credit {
                      document {
                        ... on PrismicCredit {
                          data {
                            months_1
                            months_2
                            percent
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicCategory(sort: { fields: data___order }) {
      edges {
        node {
          uid
          data {
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 235
                    transformOptions: { fit: CONTAIN }
                  )
                }
              }
            }
            name
            children {
              child {
                document {
                  ... on PrismicSubcategory {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicFooterBody2Social {
      edges {
        node {
          primary {
            social_name {
              raw
            }
            social_img_white {
              localFile {
                publicURL
              }
              alt
            }
            link {
              url
            }
          }
        }
      }
    }
    allPrismicProduct {
      edges {
        node {
          id
          uid
          data {
            model_document {
              document {
                ... on PrismicProductModel {
                  uid
                  data {
                    model_text {
                      html
                    }
                    name {
                      text
                    }
                  }
                }
              }
            }
            code_model
            brand {
              uid
              document {
                ... on PrismicBrand {
                  data {
                    name
                  }
                }
              }
            }
            category {
              uid
              document {
                ... on PrismicSubcategory {
                  data {
                    name
                  }
                }
              }
            }
            body {
              ... on PrismicProductBodySeller {
                id
                slice_type
                primary {
                  seller_logo {
                    alt
                    localFile {
                      publicURL
                    }
                  }
                  seller_name
                  seller_entity
                  ogrn
                  city
                  match_city_price
                }
              }
            }
          }
        }
      }
    }
  }
`
